<template>
  <v-container>
    <v-row>
      <v-col class="items-center justify-center text-center">
          <v-btn @click="removeFile" outlined color="primary">Zmień plik</v-btn>
      </v-col>
    </v-row>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-subheader>Zakres pożyczki</v-subheader>
        <v-card-text>
          <v-row>
            <v-col class="px-4">
              <v-range-slider
                v-model="range"
                :max="max"
                :min="min"
                step="100"
                :disabled="loading"
                hide-details
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="range[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    style="width: 60px"
                    type="number"
                    @change="$set(range, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="range[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    style="width: 60px"
                    type="number"
                    @change="$set(range, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="ccItems"
          :loading="loading"
          fixed-header
          :items-per-page="items.length"
          :loading-text="loadingText"
          :no-results-text="noResultsText"
          :no-data-text="noDataText"
          class="table-rounded"
          hide-default-footer
          height="60vh"
        >
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import FileService from "@/services/FileService";
import CCService from "@/services/CCService";
export default {
  name: 'CCService',
  data: () => ({
    search: '',
    icons: {},
    items: [],
    loading: true,
    fileList: [],
    loadingText: "Ładowanie danych...",
    min: 3000,
    max: 60000,
    range: [3000, 30000],
    noResultsText: 'Nie znaleziono wariantów spełniających kryteria',
    noDataText: 'Dane zostaną wyświetlone po dodaniu pliku',
    headers: [
      {text: 'KWOTA', value: 'value'},
      {text: 'OKRES', value: 'period'},
      {text: 'UBEZPIECZENIE', value: 'insurance'},
      {text: 'PROWIZJA MIN', value: 'minCommission'},
      {text: 'PROWIZJA MAX', value: 'maxCommission'},
      {text: 'KONSOLIDACJA', value: 'consolidation'},
      {text: 'RATA', value: 'installment'},
      {text: 'RATA KONSOLIDACJA', value: 'installmentCons'}
    ]
  }),
  mounted() {
    this.refreshCCV(this.$route.params.id)
  },
  computed: {
    ccItems () {
      if (this.range[0] !== this.min || this.range[1] !== this.max) {
        return this.items.filter(i => (i.value >= this.range[0]) & (i.value <= this.range[1]))
      } else {
        return this.items
      }
    }
  },
  methods: {
    removeFile() {
      this.$router.back()
    },
    refreshCCV(path) {
      CCService.getCCV(path).then(response => {
        this.items = response.data.data
        if (this.items.length == 0) {
          this.noDataText = 'Brak ofert w pliku'
        }
        this.min = response.data.min
        this.max = response.data.max
        this.range = [response.data.min, response.data.max]
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.items = []
        console.info(error.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
