import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ts_tok',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export default {
  getCCV(path) {
    if (path) {
      return apiClient.get(`/cc/${path}`)
    } else {
      return new Error('Error')
    }
  }
}
